.watched-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        .title {
            margin-top: 14px;
            color: #878E98;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 169.231% */
            letter-spacing: -0.8px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px;
                height: 16px;
                margin-right: 3px;
            }
        }

        .time {
            margin-top: 10px;
            color: #242424;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            text-align: center;
        }
    }

    .right {
        flex: 1;
        box-sizing: border-box;
        padding: 0px 15px;
        .ant-slider{
            cursor:initial;
            .ant-slider-mark-text{
                cursor:initial;
            }
        }
    }
}