.h5-personalPage-container {
    width: 100%;

    .wallet-container {
        .user-info {
            display: flex;
            align-items: center;

            .head-img {
                width: 64px;
                height: 64px;
                margin-right: 24px;
                border-radius: 50%;
            }

            .user-detal {
                flex: 1;

                .soga-name {
                    color: #242424;

                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    display: flex;
                    .img {
                        cursor: pointer;
                        vertical-align: middle;
                        margin-left: 6px;
                        width: 16px;
                        height: 16px;
                    }
                }

                .soga-id {
                    display: flex;
                    color: #878E98;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin-top: 8px;

                    .img {
                        cursor: pointer;
                        vertical-align: middle;
                        margin-left: 6px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }



        }

        .follow-card {
            border-radius: 14px;
            background: #FFF;
            display: flex;
            align-items: center;
            height: 80px;
            margin-top: 32px;

            .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .frist {
                    color: #0F172A;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 135%;
                }

                .end {
                    color: #878E98;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    margin-top: 8px;
                }  
            }

            .middle {
                width: 1px;
                height: 40px;
                opacity: 0.25;
                background: #878E98;
            }

        }

        .card {
            border-radius: 14px;
            background: #FFF;
            margin-top: 16px;

            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 24px;
                height: 50px;

                border-bottom: solid #878E9840 1px;

                .title {
                    color: #242424;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .btn {
                    padding: 4px 12px;
                    border-radius: 40px;
                    background: #000;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: #FFF;
                    cursor: pointer;

                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .body {
                padding: 0px 24px;

                .item {
                    height: 74px;
                    border-bottom: solid #F3F7F9 1px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left {
                        display: flex;
                        align-items: center;
                        img {
                            width: 40px;
                            height: 40px;
                            margin-right: 12px;
                            vertical-align: middle;
                        }

                        color: #242424;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                    }

                    .right {
                        .a {
                            color: #242424;
                            text-align: right;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px;
                        }

                        .b {
                            color: #878E98;
                            text-align: center;
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px
                        }
                        .btn {
                            padding: 4px 12px;
                            border-radius: 40px;
                            background: #000;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: #FFF;
                            cursor: pointer;
        
                            font-family: Poppins;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                        .fbtn{
                            background: #878E98;
                        }
                    }
                }
            }
        }
    }

    .personal-container {
        .personal-head {
            img {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 10px;
                cursor: pointer;
            }


            color: #000;
            font-family: "PingFang SC";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align:left;
            display: flex;
            align-items: center;
        }

        .head-img {
            width: 88px;
            height: 88px;
            border-radius: 50%;
            margin: auto;
            margin-top: 31px;

            img {
                width: 88px;
                height: 88px;
                border-radius: 50%;
            }
        }

        .from-card {
            margin-top: 48px;

            .label {
                color: #242424;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .personal-input {
                border: 0px;
                height: 48px;
                border-radius: 52px;
                padding: 12px 24px
            }

            .personal-TextArea {
                border: 0px;
                border-radius: 16px;
                padding: 12px 24px
            }

            .btn {
                margin: auto;
                width: 180px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 40px;
                background: #000;
                cursor: pointer;
                margin-top: 110px;

                color: #FFF;

                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

}