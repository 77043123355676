.h5-soga-quest-container{
    width: 100%;
   
    .h5-questItem {
        height: 192px;
        width: 100%;
        border-radius: 14px;
        background: #FFF;
        padding: 24px 22px;
        box-sizing: border-box;
        margin-bottom: 14px;
        cursor: pointer;

        .top {
            display: flex;
            justify-content: space-between;

            .img {
                width: 28px;
                height: 28px;
            }

            .btn {
                background-color: #000000;
                height: 26px;
                border-radius: 40px;
                color: #FFF;
                font-family: "PingFang SC";
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .box-line {
            img {
                width: 32px;
                height: 32px;
                margin-right: 5px;
            }

            color: #000;
            font-family: "PingFang SC";
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top:19px;

            display: flex;
            align-items: end;
        }

        .title {
            color: #000;
            font-family: "PingFang SC";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 25px;
            text-align: left;
        }

        .subTitle {
            color: #667681;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
        }
    }
    .quest-info-head {
        img {
            width: 16px;
            height: 16px;
            vertical-align: middle;
            margin-right: 10px;
            cursor: pointer;
        }


        color: #000;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align:left;
        display: flex;
        align-items: center;
    }

    .info-items {
        border-radius: 14px;
        background: #FFF;
        margin-top: 16px;

        .info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 61px;
            border-bottom: 1px solid rgba(135, 142, 152, 0.25);
            padding: 0px 16px;

            .left {
                img {
                    width: 18px;
                    height: 18px;
                    vertical-align: middle;
                    margin-right: 10px;
                }

                color: #000;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                flex: 1;
                display: flex;
            }

            .btn {
                height: 38px;
                border-radius: 40px;
                background: #000;
                padding: 0px 12px;


                color: #FFF;
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
            }
        }

        .info-item:last-child {
            border: 0px;
        }

    }
    .reward-item {
        margin-top: 14px;
        border-radius: 14px;
        background: #FFF;

        .top {
            height: 52px;
            border-bottom: 1px solid rgba(135, 142, 152, 0.25);
            display: flex;
            align-items: center;
            padding: 0px 15px;
            color: #000;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            box-sizing: border-box;
        }

        .bottom {
            padding-bottom: 12px;


            .title {
                margin: auto;
                margin-top: 14px;
                color: #000;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
            }

            .box-img {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 62px;
                    height: 62px;
                }
            }

            .openBtn {
                margin: auto;
                height: 38px;
                padding: 0px 12px;
                border-radius: 40px;
                background: rgba(0, 0, 0, 0.20);
                width: fit-content;
                margin-top: 22px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }

    .inviting-quest-title {
        margin: auto;
        margin-top: 31px;
        // width: 390px;
        color: #242424;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        label {
            color: #FCB328;
            font-size: 24px;
        }

        img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
            display: inline;
        }
    }

    .inviting-quest-Cumulative {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .title {
            color: #242424;
            text-align: center;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .cedis {
            width: 56px;
            height: 56px;
            position: relative;
            margin-top: 6px;

            img {
                width: 56px;
                height: 56px;
            }

            .Number {
                color: #000;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                position: absolute;
                right: -40px;
                transform: translateY(-50%);
                top: 50%
            }
        }
    }

    .inviting-quest-reward-plan {
        padding: 31px;
        box-sizing: border-box;
        border-radius: 14px;
        background: #FFF;
        margin-top: 32px;
        position: relative;

        .reward-title {
            color: #242424;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .card-tip {
            position: absolute;
            left: 0px;
            top: 0px;
            display: inline-flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            border-radius: 16px 0px;
            background: linear-gradient(90deg, #F68632 0%, #E04C41 100%);

            color: #FCFCFD;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            /* 114.286% */
        }
    }

    .my-info {
        margin-top: 33px;
        padding-bottom: 30px;
        .info-row {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;

            .title {
                width: 130px;

                color: #878E98;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 169.231% */
                text-transform: capitalize;
            }

            .value {
                color: #242424;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-left: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                /* 150% */
            }

            .link-card {
                display: flex;
                align-items: center;

                .link {
                    flex: 1;
                    overflow: hidden;
                    width: 255px;

                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .btn {
                padding: 4px 12px;
                height: 28px;
                background: red;
                margin-left: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 40px;
                background: #242424;
                color: #FFF;

                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                cursor: pointer;
            }
        }
    }

}