.live-operate-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .info-container {
        height: 36px;
        border-radius: 37px;
        background: #E7E8EA;
        display: flex;
        align-items: center;
        font-size: 14px;

        .headImg {
            height: 36px;
            width: 36px;
            border-radius: 50%;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #B1B1B1;
        }

        .name {
            color: #727376;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            height: 100%;
            padding: 0px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .follow {
            background: #000;
            padding: 10px;
            box-sizing: border-box;
            color: #FFF;
            font-weight: 700;
            height: 100%;

            padding: 0px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 37px;
            cursor: pointer;
            height: 32px;
            width: 95px;
        }

        .Following { 
            cursor: pointer;
            .soga-a {
                display: initial;
            }

            .soga-b {
                display: none;
            }
        }

        .Following:hover {
            .soga-a {
                display: none;
            }

            .soga-b {
                cursor: pointer;
                display: initial;
                color: red;
            }
        }
    }

    .operate-container {
        display: flex;

        >img {
            width: 46px;
            height: 46px;
            margin-left: 10px;
            cursor: pointer;
        }

        .gift-fist {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: #E2E2E2;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                width: 40px;
                height: 40px;
            }
        }
    }

    .people-number {
        padding: 9px 12px;
        text-align: center;
        color: #242424;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        height: 36px;
        border-radius: 40px;
        background: #E7E8EA;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        img {
            vertical-align: middle;
            width: 24px;
            height: 20px;
            margin-right: 8px;
        }
    }
}