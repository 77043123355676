.h5-btn-group{
    height: 36px; 
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px; 
    font-size: 15px;
    border-radius: 32px;
    background: #ffffff;
    div{
        min-width: 106px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        background: #FFFFFF;

        cursor: pointer;

        color: #878E98;
        text-align: center;
        font-family: Poppins; 
        font-style: 400;
        line-height: 24px;
        padding: 0px 10px;
    }
    .active {
        background: #242424;
        color: #FFF;

        text-align: center;
        font-family: Poppins; 
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}