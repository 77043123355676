.h5-send-gfit-message {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;;
    align-items: center;
    justify-content: center;
    width: 90%;
 
    transform: translateX(-50%) translateY(-50%);
    background: white;
    border-radius: 16px;

    padding: 15px 20px;

    background-color: #ffffff;
    background: linear-gradient(140deg, #FFF 0%, #D8D9DD 100%);
    .modal-close {
        position: absolute;
        width: 36px;
        height: 36px;
        top: -7px;
        right: -10px;
        cursor: pointer;
    }
    .gift-info {
        height: 86px;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 18px;
        border-bottom: 1px solid #878E98;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .gift-icon {
            width: 68px;
            height: 68px;
            background-color: #E2E2E2;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60px;
                height: 60px;
            }
        }

        .gift-name {
            color: #000;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.4px;
            margin-left: 12px;
        }

        .cedis-icon {
            width: 24px;
            height: 24px;
            margin-left: 10px;
        }

        .gift-price {
            color: #FFBD00;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.32px;
            margin-left: 4px;
        }
    }
    .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .count-btn {
            display: grid;
            grid-template-columns: 100px 100px;
            grid-template-rows:32px 32px;
            column-gap: 20px;
            row-gap: 20px;
            margin-top: 7px; 
            >div {
                color: #000;

                font-family: PingFang SC;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                width: 79px;
                height: 32px;
                border-radius: 68px;
                border: 1px solid #000;
                cursor: pointer;

                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
            }

            >div.selected {
                background: #000;
                color: #FFFFFF;
            }
        }

       
    }
    .btm-div{
        display: flex;
        justify-content: space-between; 
        align-items: center;
        margin-top: 18px;
        width: 100%;
        .send-btn {
            cursor: pointer;
            color: #FFF;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 79px;
            height: 32px;
            border-radius: 68px;
            background: #000;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .count-price {
        display: flex; 
        align-items: center;

        .total {
            color: #000;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
        }

        .cedis-icon {
            width: 24px;
            height: 24px;
            margin-left: 10px;
        }

        .gift-price {
            color: #FFBD00;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;
            margin-left: 4px;
        }
    }

    .invitation-modal {
        padding: 32px 0px;

        .invitaion-title {
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            width: 100%;
            text-align: center;
            margin-bottom: 41px;
        }

        .invitation-input {
            border: 0px;
            height: 48px;
            border-radius: 52px;
            padding: 12px 24px
        }

        .input-tip {
            margin-top: 12px;
            padding-left: 24px;
            color: #878E98;

            leading-trim: both;

            text-edge: cap;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

        .btn {
            margin: auto;
            margin-top: 50px;

            height: 52px;
            width: 180px;

            color: #FFF;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 40px;
            background: #000;

            cursor: pointer;
        }
    }

    .box-result-context {
        padding: 10px 0px 20px 0px;
        box-sizing: border-box;

        .box-img {
            img {
                width: 120px;
                height: 120px;
                margin: auto;
            }

            text-align: center;
            margin: auto;
        }

        .tips {
            color: #000;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.64px;


            text-align: center;
            margin: auto;
            margin-top: 8px;
        }

        .subtips {
            color: #000;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.44px;
            text-align: center;
            margin: auto;
        }

        .cedis {
            text-align: center;
            margin: auto;
            margin-top: 30px;

            img {
                width: 50px;
                height: 50px;
                margin: auto;
            }
        }

        .amount {
            color: #242424;
            font-family: "PingFang SC";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            margin: auto;
        }
    }

    .send-transfer-message {
 
        .title {
            color: #000;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .subTitle {
            color: #878E98;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .normalInput {
            width: 100%;
            height: 48px;
            border-radius: 57px;
            background: #FFF;
            display: flex;
            align-items: center;
        }

        .normal-container {
            margin-top: 14px;
            position: relative;
            margin-bottom: 26px;

            .normalInput:first-child {
                margin-bottom: 10px;
            }

            .normalInput {
                .left {
                    width: 106px;
                    padding-left: 16px;
                    color: #878E98;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: PingFang SC;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    /* 216.667% */
                }

                .right {
                    color: #000;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: PingFang SC;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    display: flex;
                    align-items: center;
                    /* 185.714% */
                    img {
                        vertical-align: middle;
                        width: 18px;
                        height: 18px;
                        margin-right: 8px;
                    }
                }
            }
        }

        .normalInput.asset {
            margin-bottom: 26px;
            padding-left: 16px;
            box-sizing: border-box;

            img {
                vertical-align: middle;
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }

            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            /* 162.5% */
        }

        .group-t {
            display: flex;
            justify-content: space-between;

            .title {
                label {
                    color: #585858;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: PingFang SC;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    /* 185.714% */
                }
            }
        }

        .ant-input-group {
            display: flex;
            background-color: #FFFFFF;
            width: 100%;
            height: 48px;
            border-radius: 57px;
            box-sizing: border-box;
            padding-left: 16px;
            align-items: center;

            .amount {
                border: 0px;
            }

            .amountAll {
                color: #FAA300;
                leading-trim: both;
                text-edge: cap;
                font-family: PingFang SC;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                cursor: pointer;
                width: 60px;
                margin-left: 6px;
            }
        }

        .freeTip {
            color: #878E98;
            leading-trim: both;
            text-edge: cap;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-top: 16px;

            /* 216.667% */
            label {
                color: #000;
            }
        }

        .btn {
            margin: auto;
            margin-top: 20px;
            width: 200px;
            height: 40px;
            border-radius: 61px;
            background: #000;

            color: #FFF;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
        }

    }
}