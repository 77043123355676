@import '../../px2rem.scss';

.h5App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #F4F4F5;
    max-width: 1000px;
    margin: auto;

    .h5Header{
        height: 40px; 
        padding: 0px px2rem(30px);
        display: flex;
        justify-content:space-between;
        align-items: center;
        border-bottom: solid 1px #ECECEE;
        background: #000000;
        img{
            width: 30px;
            height: 30px;
        }
    }

    .h5body {
        flex: 1;
        display: flex;
        padding: px2rem(30px);
        // justify-content: center;
        // align-items: center;
        overflow: auto;
    }

    .H5Bottom {
        flex: 0;
        border-top: solid 1px #ECECEE;
        background: #FFFFFF;
    }

    .icon-item {
        width: 28px;
        height: 28px;
    }
}
.h5-SpinLoading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}