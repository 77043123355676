.h5-gifts-modal {
    display: grid;
    grid-template-columns: 25% 25% 25% 25% ;
    padding: 30px 15px 0px 15px;

    position: relative;
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 29px;
        cursor: pointer;

        .gift {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            background-color: #E2E2E2;
            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 15px;

            img {
                width: 60px;
                height: 60px;
            }

            ;
        }

        .info {
            display: flex;
            justify-content: center;

            .cedis {
                width: 24px;
                height: 24px;
                vertical-align: middle;
                margin-right: 7px;
            }

            color: #FAA300;
            text-align: right;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.32px;
        }
    }


}