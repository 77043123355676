.container {
  position: absolute;
  bottom: 1.5rem;
}

.gift-animation {
  position: absolute;
  width: 100px;
  height: 100px;
  // background-image: url('../../assets/svgs/flower.png'); /* 替换成你的礼物图片 */
  background-size: cover;
  animation: animateGift 2s linear;
  opacity: 0;
  .content {
    position: relative;
    img {
      width: 92px;
      height: 92px;
    }
    .count{
      position: absolute;
      font-size: 0.20rem;
      bottom: 0px;
      color:#FFF;
      font-weight: 700;
      right: 0px;
      transform:translateX(100%); 
      font-family: PoppinsBoldItalic;
      text-shadow: 2px 0px 0px #6714EC;
      label{
        font-size: 0.12rem;
      }
    }
  }
}

@keyframes animateGift {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
}
