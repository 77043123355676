.h5-soga-emoticon {
  width: 236px;
  height: 200px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  img {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 0 4px 4px;
  }
}