.tv-video-container {
    width: 100%; 
    aspect-ratio: 16 / 9  !important;
    .tv-video{
        width: 100%;
        height: 100%;
    }
    .ad-video{
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        z-index: 3; 
        background-color: red;
        video{
            width: 100%;
        }
    }
    .ad-video-play{
        display: initial;
    }
    .ad-video-end{
        display: none;
    }
    #custom-video_html5_api{
        width: 100%;
        height: 100%;
    }
}