.home-container {


    .no-live {
        height: 572px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 199px;
            height: 136px;
        }

        color: #878E98;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .h5-roomName {
        color: rgb(0, 0, 0);
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-top: 8px;
        text-align: left;
    }

    .audio {
        width: 100%;
        box-sizing: border-box;
        aspect-ratio: 16 / 9;
        background-color: rgb(0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .message-container {
        width: 100%;
        height: 166px;
        margin: 25px 0px;
        border-bottom: 1px solid rgba(135, 142, 152, 0.25);

        .item {
            border-radius: 35px;
            background: rgba(0, 0, 0, 0.20);
            color: #FFF;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
            width: fit-content;
            padding: 4px 10px;

            box-sizing: border-box;
        }

    }

    .message-container.soga-nomessage {
        height: 0px;
    }
}