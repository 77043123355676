.soga-h5-video-tools {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 0;
    width: 100%;
    background-color: #33333380;

    display: none;
    align-items: center;
    color: #ffffff;
    padding: 0px 20px;
    box-sizing: border-box;
    font-size: 14px;


    transition: height 0.3s ease;
    .tools-icons{
        margin-right: 20px;
        display: flex;
        >img{
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    } 
}

.soga-h5-video-tools.show{
    display: flex;
    height: 30px;
}