.h5-connectWallet-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 146px;

    .h5-smallLogo {
        width: 108px;
        height: 108px;
        margin-bottom: 100px;
    }
    .H5-ntfImg{
        margin-bottom: 180px;
    }

    .text {
        font-size: #000;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .btn{
        background-color: #000;
        color:#FFFFFF;
        display: flex; 
        align-items: center;
        justify-content: center;
        font-size: 24px;
        width: 300px;
        height: 60px;
        border-radius: 61px;
        img{
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }

}