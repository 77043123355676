.soga-connectBtn{
    height: 30px;
    padding:0px 15px;
    border:1px solid #FECE1E;
    color: #FECE1E;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}
.soga-connectBtn.soga-wrong{
    border:1px solid #FF494A;
    background-color: #FF494A;
    color: #fff;
}