 .h5-message-write {
     border-radius: 16px;
     background: rgba(0, 0, 0, 0.20);
     padding: 12px 16px;
     height: 46px;
     color: #FFF;

     font-family: Poppins;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
     flex: 1;
     box-sizing: border-box;
 }

 .h5-send-message-card {
     flex: 1;
     border-radius: 16px;
     border: 1px solid #000;
     background: #FFF;
     padding: 10px 16px;
     box-sizing: border-box;

     .btns {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-top: 10px;

         .btn {
             height: auto;
             padding: 4px 12px;
             display: flex;
             align-items: center;
             justify-content: center;

             box-sizing: border-box;
             border-radius: 40px;
             background: #000;
             color: #FFF;
             font-family: Poppins;
             font-size: 13px;
             font-style: normal;
             font-weight: 400;
             line-height: normal;

             cursor: pointer;
         }

         .disabledBtn {
             opacity: 0.5;
         }
     }
 }