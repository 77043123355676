.h5-ProgressScss {
  margin-top: 64px;
  margin-bottom: 40px;

  .inner {

    .ant-slider-rail {
      background: #F4F4F5 !important;
    }

    .ant-slider-track {
      background: none !important;

      background: linear-gradient(90deg, #FF6B00 0%, #FFE092 100%) !important;
    }

    .ant-slider-dot-active {
      position: absolute;
      height: px2rem(24px) !important;
      width: px2rem(24px) !important;
      border-radius: 50%;
      border: none;
      background: #c7e0fa !important;
      top: px2rem(-5px);
    }


    .ant-slider-handle {
      display: none;
    }

    .people {
      position: relative;
      height: 24px;
      width: 24px;
      top: -20px;

      img {
        width: 24px;
        height: 24px;
      }

      .active {
        cursor: pointer;
      }

      .complate {
        width: 8px;
        height: 8px;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }

      .amount {
        position: absolute;
        top: -25px;
        color: #000;

        text-align: center;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        text-align: center;
        width: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 30px;
      }

      .peopleCount {
        position: absolute;
        bottom: -25px;

        color: #878E98;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        left: 50%;
        transform: translateX(-50%);
      }
    }

  }

  .ant-slider-disabled {
    cursor: default !important;
  }


}

.h5-timeProgressScss {
  margin-top: 50px;

  .timeInner {
    .ant-slider-rail {
      background: #FFFFFF !important;
    }
  }
}