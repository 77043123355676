// @normal: #3F66E0;
// @edit: #00C7B8;
// @rest: #FBA908;
// @delete: #EC2424;
// @other: #09BCE4;
// @active: #00f6f0;
// 可视化组件标题高度
// @titleHeight: 25px;
@import "~antd/dist/antd.css";

:root {
  --color-normal: #3f66e0;
  --color-edit: #00c7b8;
  --color-rest: #fba908;
  --color-delete: #ec2424;
  --color-other: #09bce4;
  --color-active: #00f6f0;
  --titleHeight: 25px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "SFCompactDisplayBlod", "SFCompactDisplay", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.App {
  width: 100%;
  height: 100vh;
  // overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(18, 14, 29);
  /* 公共样式 */
  /* 按钮 */
  /* 新增 导入 导出 */
  .n-btn {
    color: #fff;
    background-color: var(--color-norma);
  }
  /* 编辑 */
  .e-btn {
    color: #fff;
    background-color: var(--color-edit);
  }
  /* 重置 */
  .w-btn {
    color: #fff;
    background-color: var(--color-rest);
  }
  /* 删除 */
  .d-btn {
    color: #fff;
    background-color: var(--color-delete);
  }
  /* 其他 */
  .o-btn {
    color: #fff;
    background-color: var(--color-other);
  }
}
.connect-modal-gourp-list,
.particle-connect-modal-gourp-list {
  > div:last-child {
    display: none;
  }
}

@font-face {
  font-family: "poppinsBold"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsLight"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsMedium"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsRegular"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "poppinsSemiBold"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "cyberjunkies"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Cyberjunkies.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBoldItalic"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "FusionPixel"; /*这个是字体文件的名字，不要把.ttf加在这里*/
  src: url("./assets/fonts/Fusion Pixel_zitidi.ttf") format("truetype");
}

.ant-input-affix-wrapper .ant-input:focus {
  border: none !important;
  box-shadow: none !important;
}

textarea,
textarea.ant-input:hover,
textarea:focus {
  border-width: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

// div {
//   user-select: none;
// }
