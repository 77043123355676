 
 .h5-soga-emoji {
  text-align: left;


  img {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}

.h5-soga-emoTooltip { 
  z-index: 20001 !important;
}