.follow-container {
    .h5-personalPage-container {
        .wallet-container {
            .quest-info-head {
                img {
                    width: 16px;
                    height: 16px;
                    vertical-align: middle;
                    margin-right: 10px;
                    cursor: pointer;
                }


                color: #000;
                font-family: "PingFang SC";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align:left;
                display: flex;
                align-items: center;
            }
        }
    }

    .adm-popup-body {
        background-color: #F4F4F5;
        padding: 0.3rem;

        // .follow-card {
        //     margin-top: 0px;
        // }

        .follow-card {
            .item {
                cursor: pointer;

                .end.active {
                    border-bottom: 2px solid #878E98;
                }
            }
        }
    }
}

.adm-action-sheet-popup {
    .adm-popup-body {
        max-width: 1000px !important;
        left: 50% !important;
        ;
        transform: translateX(-50%) !important;
    }
}