.h5-myNfts-container {
    // padding: 32px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, 212px);
    grid-gap: 24px ;
    box-sizing: border-box;
    width: 100%;
    .card {
        width: 100%;
        height: 212px;
        border-radius: 16px;
        background-color: #fff;
        padding: 12px;


        display: flex;
        flex-direction: column; 
        align-items: center;
        box-sizing: border-box;
        .card-img,
        .card-img img {
            width: 150px;
            height: 150px;
            border-radius: 12px;
        }

        .title {
            color: #0F172A;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600; 
            margin-top: 14px;
        }
    }
}