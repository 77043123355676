.gift-effect-container {
  text-align: center;
}

.gift-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  // padding: 10px 20px;
  border-radius: 8px;
  // animation: giftAnimation 3s ease-in-out;
  width: 200px;
  height: 200px;
  .gift-effect-container {
    position: relative;
    width: 100%;
    height: 100%;
    .blindBox_img {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
    .blindBoxGifts {
      position: absolute;
      top: 20px;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
      margin: 0px 5px;
      padding-right: 36px;
      animation: giftItemAnimation 3s linear;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 5px;
        width: fit-content;
      }
      img {
        width: 32px;
        height: 32px;
      }
      .gift-number {
        font-family: PoppinsBoldItalic;
        color:#FFF;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
        font-size: 14px; 
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

//   .username {
//     font-weight: bold;
//     margin-bottom: 5px;
//   }

//   .gift-name {
//     font-style: italic;
//   }

// @keyframes giftAnimation {
//   0% {
//     opacity: 0;
//     transform: translate(-50%, -50%) scale(0.2);
//   }
//   50% {
//     opacity: 1;
//     transform: translate(-50%, -50%) scale(1);
//   }
// }

@keyframes giftItemAnimation {
  0% {
    opacity: 0;
    top: 100px;
    transform: translateX(-50%) scale(0.2);
  }
  50% {
    opacity: 0;
    top: 100px;
    transform: translateX(-50%) scale(0.2);
  }
  60% {
    opacity: 1;
    top: 20px;
    transform: translateX(-50%) scale(1);
  }
}
