.home-container {
    .chat-window {
        height: 100%;
        // overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
        // padding: 24px 24px 60px;
        word-wrap: break-word;
        position: relative;

        >ul {
            padding: 0;
        }

        .enterInfo {
            font-size: 12px;
            color: #A7A7A7;
            text-align: left;
            margin: 10px;
            font-weight: Light;
            position: absolute;
            bottom: 0;
            left: 14px;
            z-index: 2;
        }

        .chat-window-tip {
            position: absolute;
            top: 0px;
            height: 40px;
            color: #333333;
            font-size: 20px;
            background-color: rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.38rem;
            z-index: 2;
            left: 0px;
            cursor: pointer;

            label {
                cursor: pointer;
            }
        }

        .chatInfo {
            list-style: none;
            color: #ffffff;
            margin-bottom: 6px;
            font-size: 12px;
            font-family: "poppinsRegular";
            word-wrap: break-word;

            // display: flex;
            .message-name {
                margin-right: 5px;
            }

            img {
                vertical-align: middle;
                display: inline;
            }

        }

        .chatInfo.redPk,
        .chatInfo.gift {
            color: #ffffff80;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .chatInfo.silenced {
            opacity: 0.6;
        }
    }



    .chat-noReadTip {
        position: absolute;
        width: 100%;
        text-align: center;
        color: #ffffff;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.6);
    }



    .chat-window-comp {
        height: 100%;
        // overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
        word-wrap: break-word;

        .ChatApp {
            background-color: transparent;

            .ChatFooter {
                display: none;
            }

            .BackBottom {
                // display: none;
                width: 100%;
                font-size: 20px;
                bottom: initial;

                .Btn {
                    border-radius: 0px;
                    width: 100%;
                    font-size: 18px;
                    padding: 0px;
                    min-width: 438px;
                    color: #666;
                }
            }

            .chatInfo {
                list-style: none;
                color: #ffffff;
                margin-bottom: 6px;
                font-size: 12px;
                font-family: "poppinsRegular";
                word-wrap: break-word;

                img {
                    vertical-align: middle;
                    display: inline;
                }
            }

            .chatInfo.redPk,
            .chatInfo.gift {
                color: #ffffff80;

                img {
                    width: 20px;
                    height: 20px;
                }
            }

            .chatInfo.silenced {
                opacity: 0.6;
            }

            .MessageContainer>.PullToRefresh>.PullToRefresh-inner {
                padding-bottom: 0px;
            }

            .Message+.Message {
                margin-top: 0px;
            }
        }
    }
}